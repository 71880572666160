import { getRandomString } from './helpers'

export const pageLoadEventName = 'Page load'
export const pageNavigationEventName = 'Page navigation'
export const pageViewEventName = 'Pageview'
export const newsletterSignUpEventName = 'Newsletter sign up'
export const bookDemoEventName = 'Book a demo'

/**
 * Triggers an event in Google Tag Manager.
 */
export const triggerGoogleTagManagerEvent = (
  event: string,
  extraAttributes?: Record<string, any>
) => {
  let dataLayerEvent = {
    event,
    eventId: getRandomString(),
  }

  if (extraAttributes) {
    dataLayerEvent = {
      ...dataLayerEvent,
      ...extraAttributes,
    }
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataLayerEvent)
}
