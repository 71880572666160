import {
  type GetServerSidePropsContext,
  type GetStaticPropsContext,
} from 'next'
import { type ParsedUrlQuery } from 'querystring'

import { type Locale, defaultLocale } from './language'

type UrlParameters = Record<string, string>

export type Slugs = string | string[] | null

export type PageType =
  | 'blogAuthor'
  | 'blogCategory'
  | 'blogPage'
  | 'blogPost'
  | 'careersPage'
  | 'dictionaryEntry'
  | 'dictionaryPage'
  | 'errorPage'
  | 'homePage'
  | 'openRole'
  | 'page'

export interface CustomStaticPropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetStaticPropsContext<Q> {
  locale: Locale
  locales: Locale[]
}

export interface CustomErrorStaticPropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetStaticPropsContext<Q> {
  locale?: Locale
  locales: Locale[]
}

export interface CustomServerSidePropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetServerSidePropsContext<Q> {
  locale: Locale
}

export interface StaticPathWithSlug {
  params: {
    slug: string
  }
  locale: Locale
}

export interface StaticPathWithSlugs {
  params: {
    slugs: string[]
  }
  locale: Locale
}

const pageRoutes: Record<PageType, string[]> = {
  homePage: [],
  errorPage: ['page-that-does-not-exist-EqchyqRX3HXNjTvx'],
  page: ['[page_slug]'],
  blogPage: ['blog'],
  blogPost: ['blog', '[post_slug]'],
  blogCategory: ['blog', 'category', '[category_slug]'],
  blogAuthor: ['blog', 'author', '[author_slug]'],
  dictionaryPage: ['dictionary'],
  dictionaryEntry: ['dictionary', '[entry_slug]'],
  careersPage: ['careers'],
  openRole: ['careers', '[role_slug]'],
}

const getUrlSegments = (
  pageType: PageType,
  slugs: string | string[] | null = null
) => {
  const urlSlugs = slugs && Array.isArray(slugs) ? slugs : [slugs]
  let slugIndex = 0

  return (
    pageRoutes[pageType]?.map((segment) => {
      // Replace segment placeholder with a slug or an empty string
      if (/\[.*\]/.test(segment)) {
        slugIndex += 1
        return urlSlugs?.[slugIndex - 1] ?? ''
      }

      return segment
    }) ?? []
  )
}

const getUrlParameterString = (parameters?: Record<string, string>) => {
  const searchParams = new URLSearchParams()

  Object.entries(parameters ?? {}).forEach((parameter) => {
    if (parameter[0] && parameter[1]) {
      searchParams.set(parameter[0], parameter[1])
    }
  })

  return searchParams.toString()
}

/**
 * Gets relative page URL for Next.js links without locale segment.
 */
export const getLinkPageUrl = (
  pageType: PageType,
  slugs?: Slugs,
  parameters?: UrlParameters
) => {
  const pageUrlSegments = getUrlSegments(pageType, slugs)
  const url = pageUrlSegments.filter(Boolean).join('/')

  const urlParameters = getUrlParameterString(parameters)
  const urlWithParameters = [url, urlParameters].filter(Boolean).join('?')

  return `/${urlWithParameters}`
}

/**
 * Gets relative page URL.
 */
export const getPageUrl = (
  locale: Locale,
  pageType: PageType,
  slugs?: Slugs,
  parameters?: UrlParameters
) => {
  const localeSegment = locale !== defaultLocale ? `${locale}` : null
  const pageUrlSegments = getUrlSegments(pageType, slugs)
  const url = [localeSegment, ...pageUrlSegments].filter(Boolean).join('/')

  const urlParameters = getUrlParameterString(parameters)
  const urlWithParameters = [url, urlParameters].filter(Boolean).join('?')

  return `/${urlWithParameters}`
}
