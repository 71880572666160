import '../public/global.css'
import '/node_modules/flag-icons/css/flag-icons.min.css'

import { SpeedInsights } from '@vercel/speed-insights/next'
import cx from 'classnames'
import { LazyMotion, domAnimation, AnimatePresence } from 'framer-motion'
import { type AppProps } from 'next/app'
import Head from 'next/head'
import { Router } from 'next/router'
import Script from 'next/script'
import { type ReactNode, Fragment, useContext, useEffect, useRef } from 'react'

import { type SanityAnyPage } from '@data/sanity/queries/types/page'
import {
  type PublicSiteSettings,
  type SanitySiteFragment,
} from '@data/sanity/queries/types/site'
import {
  pageLoadEventName,
  pageNavigationEventName,
  pageViewEventName,
  triggerGoogleTagManagerEvent,
} from '@lib/analytics'
import { pageTransitionSpeed } from '@lib/animate'
import { gtWalsheim } from '@lib/fonts'
import { getRandomString } from '@lib/helpers'
import { LanguageContextProvider, type Locale } from '@lib/language'
import { SiteContext, SiteContextProvider } from '@lib/site'
import { StringsContextProvider } from '@lib/strings'

import RouteChangeProgressBar from '@components/route-change-progress-bar'

interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

interface NextHistoryState {
  url: string
  as: string
  options: TransitionOptions
}

interface AppPageProps {
  draftMode: boolean
  draftToken?: string
  locale: Locale
  site: SanitySiteFragment | null
  page: SanityAnyPage | null
}

type DefaultAppProps = AppProps<AppPageProps>

interface CustomAppProps extends DefaultAppProps {
  pageProps: AppPageProps
}

interface SiteProps extends Pick<DefaultAppProps, 'router'> {
  pageProps: AppPageProps
  children: ReactNode
}

/**
 * Add new position to scroll positions.
 */
const addScrollPosition = (
  positions: Record<string, number>,
  locale: Locale,
  url: string,
  position: number
) => {
  const key = `${locale}:${url}`
  const alternativeKey = `${locale}:/${locale}${url.replace(/\/+$/g, '')}`

  return {
    ...positions,
    [key]: position,
    [alternativeKey]: position,
  }
}

/**
 * Router event handler hook.
 */
const useRouterEvents = (router: Router, locale: Locale) => {
  const { toggleIsRouteChanging, toggleMobileMenu } = useContext(SiteContext)

  const scrollPositions = useRef<Record<string, number>>({})
  const shouldScrollRestore = useRef(false)
  const isInitialLoad = useRef(true)

  useEffect(() => {
    // Prevent browser scroll restoration
    window.history.scrollRestoration = 'manual'
  }, [])

  // Setup router events
  useEffect(() => {
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      if (!isInitialLoad.current) {
        // Save scroll position
        scrollPositions.current = addScrollPosition(
          scrollPositions.current,
          locale,
          router.asPath,
          window.scrollY
        )
      }

      delete event['returnValue']
    }

    function handleRouteChangeStart(_: string, { shallow }: TransitionOptions) {
      toggleMobileMenu(false)

      if (!isInitialLoad.current) {
        // Save scroll position
        scrollPositions.current = addScrollPosition(
          scrollPositions.current,
          locale,
          router.asPath,
          window.scrollY
        )
      }

      // Check if URL is changing
      if (!shallow) {
        toggleIsRouteChanging(true)
      }
    }

    function handleRouteChangeComplete(
      url: string,
      { shallow }: TransitionOptions
    ) {
      // Wait for page transition to complete
      setTimeout(() => toggleIsRouteChanging(false), pageTransitionSpeed)

      // Check if URL is changing
      if (!isInitialLoad.current && !shallow) {
        // Restore scroll position after route change completes
        const position = scrollPositions.current[`${locale}:${url}`]
        const top = position && shouldScrollRestore.current ? position : 0

        // Restore scroll position or set it to 0
        setTimeout(
          () => requestAnimationFrame(() => window.scrollTo({ top })),
          pageTransitionSpeed + 100
        )

        shouldScrollRestore.current = false
      }

      // Wait for document title to update
      setTimeout(() => {
        triggerGoogleTagManagerEvent(pageViewEventName, {
          pagePath: window.location.pathname,
          pageTitle: document.title,
        })
        triggerGoogleTagManagerEvent(pageNavigationEventName, {
          pagePath: window.location.pathname,
          pageTitle: document.title,
        })
      }, pageTransitionSpeed + 101)

      isInitialLoad.current = false
    }

    function handleRouteChangeError() {
      toggleIsRouteChanging(false)
    }

    function handleBeforePopState({ options }: NextHistoryState) {
      // Allow scroll position restoring
      shouldScrollRestore.current = true
      options.scroll = false

      return true
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)
    router.beforePopState(handleBeforePopState)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
      router.beforePopState(() => true)
    }
  }, [locale, router, toggleMobileMenu, toggleIsRouteChanging])
}

/**
 * Loads external scripts using next/script.
 */
const getExternalScripts = (publicSettings?: PublicSiteSettings) => {
  const nonce =
    typeof window !== 'undefined'
      ? document
          .querySelector('[property="csp-nonce"]')
          ?.getAttribute('content')
      : null

  if (!nonce) {
    return []
  }

  const externalScripts: ReactNode[] = []

  if (publicSettings?.cookieBotId) {
    externalScripts.push(
      <Script
        id="Cookiebot"
        nonce={nonce}
        src="https://consent.cookiebot.com/uc.js"
        strategy="afterInteractive"
        data-cbid={publicSettings.cookieBotId}
        data-blockingmode="auto"
      />
    )
  }

  if (publicSettings?.gtmContainerId) {
    const pageLoadEventId = getRandomString()

    externalScripts.push(
      <Script
        id="google-tag-manager-variables"
        nonce={nonce}
        strategy="afterInteractive"
      >
        {`
          window['dataLayer'] = window['dataLayer'] || []
          window['dataLayer'].push({ eventId: '${pageLoadEventId}'});
          window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        `}
      </Script>
    )
    externalScripts.push(
      <Script
        id="google-tag-manager"
        src={`https://www.googletagmanager.com/gtm.js?id=${publicSettings.gtmContainerId}`}
        nonce={nonce}
        strategy="lazyOnload"
      />
    )
  }

  if (publicSettings?.analyticsId) {
    externalScripts.push(
      <Script
        id="google-analytics-variables"
        nonce={nonce}
        strategy="afterInteractive"
      >
        {`
          window['GoogleAnalyticsObject'] = 'ga'
          window['ga'] = window['ga'] || function () {
            window['ga'].q = window['ga'].q || []
            window['ga'].q.push(arguments)
          }
          window['ga'].l = 1 * new Date()

          window.ga('create', '${publicSettings.analyticsId}', 'auto', { allowLinker: true })
          window.ga('require', 'linker')
          window.ga('linker:autoLink', [])

          window.ga('send', 'pageview')
        `}
      </Script>
    )
    externalScripts.push(
      <Script
        id="google-analytics"
        src="https://www.google-analytics.com/analytics.js"
        nonce={nonce}
        strategy="lazyOnload"
      />
    )
  }

  return externalScripts
}

const Site = ({ router, pageProps, children }: SiteProps) => {
  const { isRouteChanging } = useContext(SiteContext)

  const externalScripts = getExternalScripts(pageProps.site?.settings)

  // Handle router events & scroll position restoration
  useRouterEvents(router, pageProps.locale)

  // Handle keyboard navigation
  useEffect(() => {
    function handleKeyDown({ key }: KeyboardEvent) {
      // Check if "tab" key was pressed
      if (key === 'Tab' && typeof window !== 'undefined') {
        document.body.classList.add('is-tabbing')
        window.removeEventListener('keydown', handleKeyDown)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  // Trigger pageview on page load, if this is the first render and there's no query string
  useEffect(() => {
    if (!router.asPath.includes('?')) {
      triggerGoogleTagManagerEvent(pageViewEventName, {
        pagePath: window.location.pathname,
        pageTitle: document.title,
      })
      triggerGoogleTagManagerEvent(pageLoadEventName, {
        pagePath: window.location.pathname,
        pageTitle: document.title,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={cx('font-sans', gtWalsheim.variable)}>
      {isRouteChanging && pageProps.site?.siteStrings?.loadingPageTitle && (
        <Head>
          <title>{pageProps.site.siteStrings.loadingPageTitle}</title>
        </Head>
      )}

      <RouteChangeProgressBar />

      <LazyMotion features={domAnimation}>
        <AnimatePresence
          mode="wait"
          onExitComplete={() =>
            document.body.classList.remove('overflow-hidden')
          }
        >
          {children}
        </AnimatePresence>
      </LazyMotion>

      {/* Load external scripts */}
      {externalScripts.map((externalScript, index) => (
        <Fragment key={index}>{externalScript}</Fragment>
      ))}
    </div>
  )
}

const CustomApp = ({ Component, pageProps, router }: CustomAppProps) => {
  if (!pageProps.site) {
    return <Component key={router.asPath.split('?')[0]} />
  }

  return (
    <StringsContextProvider siteStrings={pageProps.site.siteStrings}>
      <SiteContextProvider publicSettings={pageProps.site.settings}>
        <LanguageContextProvider
          locale={pageProps.locale}
          publicLocales={pageProps.site.publicLocales}
        >
          <Site router={router} pageProps={pageProps}>
            <Component key={router.asPath.split('?')[0]} {...pageProps} />
            <SpeedInsights />
          </Site>
        </LanguageContextProvider>
      </SiteContextProvider>
    </StringsContextProvider>
  )
}

export default CustomApp
