import {
  type ReactNode,
  type Dispatch,
  type SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { type PublicSiteSettings } from '@data/sanity/queries/types/site'
import { type SanityVideo } from '@data/sanity/queries/types/video'
import { screens } from '@lib/theme'

type MegaNavUpdateState = boolean | 'toggle'

interface SiteContextMegaNavigation {
  isOpen: boolean
  activeId?: string
}

interface SiteContextVideoModal {
  isOpen: boolean
  video?: SanityVideo
}

interface SiteContextMobileMenu {
  isOpen: boolean
}

type SiteContextMegaNavigationTimeout = number | null

interface SiteContextProps {
  headerHeight: number
  isRouteChanging: boolean
  megaNavigation: SiteContextMegaNavigation
  megaNavigationTimeout: SiteContextMegaNavigationTimeout
  mobileMenu: SiteContextMobileMenu
  videoModal: SiteContextVideoModal
  settings: PublicSiteSettings | null
  setHeaderHeight: (height: number) => void
  setMegaNavigationTimeout: Dispatch<
    SetStateAction<SiteContextMegaNavigationTimeout>
  >
  toggleIsRouteChanging: (newState: boolean) => void
  toggleMegaNavigation: (updateState: MegaNavUpdateState, id?: string) => void
  toggleMobileMenu: (isOpen: boolean) => void
  toggleVideoModal: (isOpen: boolean, video?: SanityVideo) => void
}

const initialSiteContext: SiteContextProps = {
  headerHeight: 0,
  isRouteChanging: false,
  megaNavigation: {
    isOpen: false,
  },
  megaNavigationTimeout: null,
  mobileMenu: {
    isOpen: false,
  },
  videoModal: {
    isOpen: false,
  },
  settings: null,
  setHeaderHeight: () => null,
  setMegaNavigationTimeout: () => null,
  toggleIsRouteChanging: () => null,
  toggleMegaNavigation: () => null,
  toggleMobileMenu: () => null,
  toggleVideoModal: () => null,
}

interface SiteContextProviderProps {
  publicSettings: PublicSiteSettings
  children: ReactNode
}

export const SiteContext = createContext<SiteContextProps>(initialSiteContext)

export const SiteContextProvider = ({
  publicSettings,
  children,
}: SiteContextProviderProps) => {
  // State variables
  const [isRouteChanging, setIsRouteChanging] = useState<boolean>(
    initialSiteContext.isRouteChanging
  )
  const [mobileMenu, setMobileMenu] = useState<SiteContextMobileMenu>(
    initialSiteContext.mobileMenu
  )
  const [megaNavigation, setMegaNavigation] =
    useState<SiteContextMegaNavigation>(initialSiteContext.megaNavigation)

  const [megaNavigationTimeout, setMegaNavigationTimeout] =
    useState<SiteContextMegaNavigationTimeout>(null)

  const [videoModal, setVideoModal] = useState<SiteContextVideoModal>(
    initialSiteContext.videoModal
  )
  const [headerHeight, setHeaderHeight] = useState(0)

  const settings = useMemo<PublicSiteSettings>(
    () => publicSettings,
    [publicSettings]
  )

  useEffect(() => {
    function handleResize() {
      // TODO: Optimize this to not set state that often.
      if (window.innerWidth >= parseInt(screens.lg)) {
        setMobileMenu({
          isOpen: false,
        })
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Update callbacks
  const toggleIsRouteChanging = useCallback(
    (newState: boolean) => setIsRouteChanging(newState),
    []
  )

  const toggleMegaNavigation = useCallback(
    (updateState: MegaNavUpdateState, id?: string) => {
      const getActiveId = (newState: boolean, id?: string) => {
        if (newState) {
          return id
        }
      }

      const isOpen =
        updateState === 'toggle' ? !megaNavigation.isOpen : updateState
      const activeId = getActiveId(isOpen, id)

      if (
        megaNavigation.isOpen !== isOpen ||
        megaNavigation.activeId !== activeId
      ) {
        setMegaNavigation({
          isOpen,
          activeId,
        })
      }
    },
    [megaNavigation]
  )

  const toggleMobileMenu = useCallback(
    (isOpen: boolean) => {
      if (mobileMenu.isOpen !== isOpen) {
        setMobileMenu({
          isOpen,
        })
      }

      if (typeof window !== 'undefined') {
        document.body.classList.toggle('overflow-hidden', isOpen)
      }
    },
    [mobileMenu.isOpen]
  )

  const toggleVideoModal = useCallback(
    (isOpen: boolean, video?: SanityVideo) => {
      setVideoModal({
        isOpen,
        video,
      })
    },
    []
  )

  return (
    <SiteContext.Provider
      value={{
        headerHeight,
        isRouteChanging,
        megaNavigation,
        megaNavigationTimeout,
        mobileMenu,
        videoModal,
        settings,
        setHeaderHeight,
        setMegaNavigationTimeout,
        toggleIsRouteChanging,
        toggleMegaNavigation,
        toggleMobileMenu,
        toggleVideoModal,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
