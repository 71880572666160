import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

/**
/**
 * Parses optional page request parameter array.
 */
export function parseOptionalParameters<T = string>(
  parameter?: T | T[] | null
): T[] | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameters<T>(parameter)
}

/**
 * Parses required page request parameter array.
 */
export function parseRequiredParameters<T = string>(parameter: T | T[]): T[] {
  return Array.isArray(parameter) ? parameter : [parameter]
}

/**
 * Parses JSON string into an object.
 */
export const parseJson = (json: string): Record<string, unknown> => {
  try {
    return JSON.parse(json)
  } catch (_) {
    return {}
  }
}

/**
 * Detects touch device
 */
export const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false)

  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0)
  }, [])

  return isTouchDevice
}

/**
 * Generates a cryptographic random string.
 */
export const getRandomString = () => Buffer.from(uuidv4()).toString('base64')
